<template>
<div class="wrapper">
  <topbar2></topbar2>
  <header2></header2>
  <div class="content-wrapper">
    <div class="content-header">
       <div class="row">    
              <div class="col-md-12">
                 <div class="card-header">
                <h3 class="card-title"> New Order </h3>
              </div>
              </div>              
            </div>     
    </div>
    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-lg-2">&nbsp;</div>
          <div class="col-lg-8 connectedSortable">
            <div class="card">            
              <div class="card-body">                
                  <div class="tab-content p-0">
                    <div class="chart tab-pane active">
                      <div>
                        <div class="row">
                          <div class="col-md-6 col-6">
                            <label>Order Date</label>
                            <input type="date" class="form-control" name="pickup_date" id="pickup_date" v-model="pickup_date"/>
                          </div>
                          <div class="col-md-6 col-6" v-if="this.app_type != '0'">                            
                            <label>Delivery Date</label>
                            <datepicker class="form-control" type="hidden" v-model="ss" />
                            <input type="date" class="form-control" name="delivery_date" id="delivery_date" v-model="delivery_date"/>
                          </div>
                        </div>
                        <div class="mheight">&nbsp;</div>
                        <div class="row">
                          <div class="col-md-4 col-7">
                            <label>Mobile No.</label>
                            <input type="number" class="form-control" placeholder="Mobile No." name="mobile_no" id="mobile_no" v-model="mobile_no"/>
                          </div>
                          <div class="col-md-2 col-5">
                            <label>&nbsp;</label>
                            <br>
                            <button v-on:click="submit()" class="btn btn-success btn-sm"><i style="font-size: 12px;" class="fas fa-search"></i> Search</button>
                          </div>
                          <div class="col-md-6" v-if="mobilesubmit">
                            <label>Customer Name</label>
                            <input type="text" class="form-control" placeholder="Customer Name" name="name" id="name" v-model="name"/>
                          </div>
                        </div>
                        <div v-if="mobilesubmit">                          
                          <div class="mheight">&nbsp;</div>
                          <div class="row">
                            <div class="col-md-4 col-3">
                              
                              <label>Item </label>
                              <select v-model="item" @change="getItem()" class="form-control">
                                <option value="" disabled hidden>Select Item</option>
                                <option v-for="service in serviceList" :key="service.id" v-bind:value="{ itemid: service.id, itemamount: service.amount, itemname: service.name }">{{service.name}}</option>
                              </select>
                            </div>
                            <div class="col-md-3 col-4">
                              <label>Qty.</label>
                              <div>
                                <button class="btn btn--minus minus" @click="changeCounter('-1')" type="button" name="button"><i class="fas fa-minus"></i></button>
                                <input class="quantity" type="number" name="sale_quantity" v-model="counter">
                                <button class="btn btn--plus plus" @click="changeCounter('1')" type="button" name="button"><i class="fas fa-plus"></i></button>
                              </div>
                              <!--<input v-model="sale_quantity" @change="updateQuantity" type="number" class="form-control" name="sale_quantity" id="sale_quantity" placeholder="Quantity">-->
                              <input v-model="sale_rate"  @change="updateRate" type="hidden" step="any" class="form-control" name="sale_rate" id="sale_rate" placeholder="Rate">
                            </div>
                            <div class="col-md-3 col-3">
                              <label>Amount</label>
                              <input v-model="sale_total" type="number" step="any" class="form-control" name="sale_total" id="sale_total" placeholder="Total Price">
                            </div>
                            <div class="col-md-2 col-2">
                              <label>&nbsp;</label>
                              <br>
                              <button @click="addCat" class="btn btn-success btn-sm"><i style="font-size:12px;" class="fas fa-plus plusadd"></i> <span class="addm">Add More</span></button>
                            </div>
                          </div>
                          <div class="mheight">&nbsp;</div>
                          <div class="row toppadservice" v-for="(cat,n) in myArray" v-bind:key="n.id">
                            <div class="col-md-4 col-3">
                              <input readonly class="form-control" type="text" v-model="cat[0].name" />
                            </div>
                            <div class="col-md-3 col-4">
                              <input readonly class="form-control" type="text" v-model="cat[0].quantity" />
                            </div>
                            <div class="col-md-3 col-3">
                              <input readonly class="form-control" type="text" v-model="cat[0].amount" />
                            </div>
                            <div class="col-md-2 col-2">
                              <button @click="removeCat(n)" class="btn btn-danger btn-sm"><i class="fa fa-times-circle"></i></button>
                            </div>
                          </div>
                          <div> </div>
                          <div class="mheight">&nbsp;</div>
                        <div class="row">
                          <div class="col-md-4">
                            <label class="mmhide">&nbsp;</label>
                            <div class="example ex1" v-if="this.app_type != '0'">
                              <label class="radio green">
                              <input type="radio" v-model="delivery_type" name="delivery_type" v-bind:value="'normal'">
                              <span>Normal</span> </label>
                              <label class="radio green">
                              <input type="radio" v-model="delivery_type" name="delivery_type" v-bind:value="'Argent'">
                              <span>Urgent</span> </label>
                            </div>
                          </div>
                          <div class="col-md-3 col-7">
                            <label>Extra Charge</label>
                            <br>
                            <select v-model="extraitem" @change="getExtra()" class="form-control">
                              <option value="" disabled hidden>Select Extra Charge</option>
                              <option v-for="extra in extraList" :key="extra.amount" v-bind:value="{ extraid: extra.id, extraamount: extra.amount, extraname: extra.name }">{{extra.name}}</option>
                            </select>
                          </div>
                          <div class="col-md-3 col-3">
                            <label>Charge</label>
                            <br>
                            <input v-model="discount" type="number" readonly class="form-control" name="discount" id="discount" placeholder="Charge">
                          </div>
                        </div>
                        <div class="mheight">&nbsp;</div>
                        <div class="row">
                          <div class="col-md-7 col-7" style="text-align:right;">
                            <label>Discount</label>
                          </div>
                          <div class="col-md-3 col-3">
                            <input v-model="extra_plus" @change="updatePlus" type="number" class="form-control" name="extra_plus" id="extra_plus" placeholder="Discount">
                            <input v-model="extra"  @change="updateRateplus" type="hidden" step="any" class="form-control" name="extra" id="extra" placeholder="Discount">
                          </div>
                        </div>
                        <div class="mheight">&nbsp;</div>
                         <div class="row">
                          <div class="col-md-7 col-7" style="text-align:right;">
                            <label>Grand Total:</label>
                          </div>
                          <div class="col-md-3 col-3 padvalleft"> {{grandtotal}}
                            {{totalRequest}} </div>
                        </div>
                        <div class="mheight">&nbsp;</div>
                         <div class="row">
                          <div class="col-md-7 col-7" style="text-align:right;">
                            <label>Advance</label>
                          </div>
                          <div class="col-md-3 col-3">
                            <input v-model="payamount" @change="updatePayamount" type="number" class="form-control" name="payamount" id="payamount" placeholder="Pay Amount">
                          </div>
                        </div>
                        <div class="mheight">&nbsp;</div>
                         <div class="row">
                          <div class="col-md-7 col-7" style="text-align:right;">
                            <label>Balance:</label>
                          </div>
                          <div class="col-md-3 col-3 padvalleft"> {{balance}}
                            <!--netamount--->
                            <input v-model="netamount" type="hidden" step="any" name="netamount" id="netamount">
                            <!--grandtotal--->
                            <input v-model="grandtotal" type="hidden" step="any" name="grandtotal" id="grandtotal">
                            <!--balance--->
                            <input v-model="balance" type="hidden" step="any" name="balance" id="balance">
                          </div>
                        </div>
                         <div class="mheight">&nbsp;</div>
                        <div class="row">
                          <div class="col-md-7 col-6"> &nbsp; </div>
                          <div class="col-md-3 col-3">
                            <button v-on:click="submitorder()" class="btn btn-success">Submit</button>
                          </div>
                        </div>
                        </div>
                        <div v-else>

         

                        </div>
                       
                      </div>
                    </div>
                  </div>                
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
  <footer2></footer2>
</div>
</template>

<script>
import { defineComponent } from 'vue'
import { createToast } from 'mosha-vue-toastify';
import 'mosha-vue-toastify/dist/style.css'

import Datepicker from 'vue3-datepicker'
import Header from './../Header.vue'
import Footer from './../Footer.vue'
import Topbar from './../Topbar.vue'
import axios from "axios";
export default {  
  extends:defineComponent,  
    components:{         
        "header2": Header,
        "footer2": Footer,
        "topbar2": Topbar,
        Datepicker    
      },
  name: 'editservice',  
    data(){             
        return {
            company_id:this.$store.state.auth.user.data[0].company_id,
            app_type:this.$store.state.auth.user.data[0].app_type,
            myData: null,
            counter: 1,
            //id: this.$route.params.id,
            user_id: '1',            
            mobile_no: '',
            name: '',            
            serviceList: [], 
            extraList: [],           
            item: "",            
            extraitem: '',
            sale_rate: '0',
            sale_quantity: '0', 
            sale_total: '0',  
            extra: '0', 
            extra_plus: '0',            
            totalextra: '0',  
            value1: '',
            value2: '', 
            totalvalue: '', 
            payamount: '0',  
            pickup_date: '2022-09-30',
            delivery_date: '2022-10-05',
            delivery_type: 'normal',
            cats:[],
            myArray:[],
            newCat:null,
            itemid: '', 
            itemamount: '', 
            itemname: '', 
            extraid: '',
            extraname: '',
            extraamount: '',
            list:[],  
            item_list:[], 
            mobilesubmit:false        
            
        }
    },
    computed: {
        Sum(){ return this.myArray.reduce( (Sum, cat) => cat[0].amount + Sum  ,0);},        
        
        balance: function() {
            return parseInt(this.extra) - parseInt(this.extra_plus) - parseInt(this.payamount) + parseInt(this.Sum);
        }, 

        grandtotal: function() {
            return parseInt(this.extra) - parseInt(this.extra_plus) + parseInt(this.Sum);
        },

        netamount: function() {
            return parseInt(this.Sum) - parseInt(this.extra_plus);
        }
    },
    mounted() {
      setTimeout(() => {
      this.myData = 'Example Data';
    }, 2000); 
	axios.post("http://laundry.ezeehost.com:4909/ClientIspmateApi/servicelist",  {
    company_id: this.company_id,
    page:'all'    
	})
      .then(res => {
        //alert(res.data.data)
        this.serviceList = res.data.data;        
        console.log(res.data)
      })
      .catch(error => {        
        console.log(error)
         // Manage errors if found any
      })

      axios.post("http://laundry.ezeehost.com:4909/ClientIspmateApi/extrachargelist",  {
    company_id: this.company_id,    
	})
      .then(res => {
        //alert(res.data.data)
        this.extraList = res.data.data;        
        console.log(this.extraList)
      })
      .catch(error => {        
        console.log(error)
         // Manage errors if found any
      }) 


      if(localStorage.getItem('cats')) {
      try {
        this.cats = JSON.parse(localStorage.getItem('cats'));
      } catch(e) {
        localStorage.removeItem('cats');
      }
    }

  },

  methods: {  
    changeCounter: function (num) {
      this.counter += +num;
      this.sale_total = this.counter * this.sale_rate
      console.log(this.counter);
      !isNaN(this.counter) && this.counter > 0 ? this.counter : this.counter = 0;

    }, 
    showsuccessmsg(msgData){
       createToast(msgData,{
          type: 'success',position: 'top-right',showIcon: true,timeout: 3000
        })
     },
     showfailedmsg(msgData){
       createToast(msgData,{
          type: 'danger',position: 'top-right',showIcon: true,timeout: 3000
        })
     },     
        updateQuantity(event) {
            this.sale_quantity = event.target.value
            this.sale_total = this.sale_quantity * this.sale_rate
        },
        updateRate(event) {
            this.sale_rate = event.target.value
            this.sale_total = this.sale_quantity * this.sale_rate
        }, 
        updatePlus(event) {            
            this.extra_plus = event.target.value
            this.total = this.total - this.extra_plus            
        },
        updatePayamount(event) {            
            this.payamount = event.target.value            
            console.log(this.payamount)         
        },
        updateRateplus(event) {
            this.extra = event.target.value
            this.discount = this.extra_plus + this.extra            
        },                      

        getItem(){
            this.itemid = this.item.itemid;        
            this.sale_rate = this.item.itemamount;        
            this.sale_total = this.item.itemamount;  
            this.base_price = this.item.itemamount; 
            console.log(this.sale_total)     
            
        },
        getExtra(){
            this.extra = this.extraitem.extraamount;        
            this.discount = this.extraitem.extraamount;  
            console.log(this.extra)               
        },

      addCat() {          
          this.list = {id:this.item.itemid,name:this.item.itemname, quantity:this.counter, amount:this.sale_total, base_price:this.base_price}

          this.cats = [{name:this.item.itemname, quantity:this.counter, amount:this.sale_total}]          

          this.myArray.push(this.cats);    
          this.item_list.push(this.list);                                      

          console.log(this.myArray);
          this.saveCats();
    },
    removeCat(x) {
      this.myArray.splice(x,1);
      this.saveCats();
    },
    saveCats() {
      let parsed = JSON.stringify(this.myArray);
      localStorage.setItem('myArray', parsed);
    },

    additem(e){
            //alert('dfdsfd');
          e.preventDefault() // it prevent from page reload
          localStorage.setItem('sale_quantity',this.sale_quantity)
          localStorage.setItem('sale_total',this.sale_total)

          console.log(this.sale_quantity);
        },


submit(){ 
  //alert('ddd');       
    axios.post('http://laundry.ezeehost.com:4909/ClientIspmateApi/searchclient', {company_id: this.company_id,mobile_no: this.mobile_no} , {
      company_id: this.company_id,
      mobile_no: this.mobile_no,
    })
        .then(response => {  
          this.mobilesubmit = true;              
        this.name=response.data.data[0].name;
        this.balance=response.data.data[0].balance;        
        console.log(response.data.data);                
        //return response
        });
},

        submitorder(){           
            axios.post('http://laundry.ezeehost.com:4909/ClientIspmateApi/placeorder', {
              user_id: this.user_id,
              company_id: this.company_id,
              app_type: this.app_type,
              mobile_no: this.mobile_no,
              name: this.name,
              pickup_date: this.pickup_date,
              delivery_date: this.delivery_date,
              delivery_type: this.delivery_type,
              grand_total: this.grandtotal,
              discount: this.extra_plus,
              net_amount: this.netamount,
              paid_amount: this.payamount,
              bal_amount: this.balance,
              item_list: this.item_list,
              extra_item: [{id:this.extraitem.extraid,name:this.extraitem.extraname,amount:this.extraitem.extraamount}]
              },
              {
              user_id: '1',              
            })
                .then(response => { 
                      this.showsuccessmsg(response.data.response);                
                      localStorage.setItem('invoice_id', response.data.data.invoice_id)                
                      this.$router.push({path:'/vieworder/'+response.data.data.invoice_id})
                      console.log(response.data); 
                      //alert(response.data);                
                      //return response
                }).catch(error => { 
                  this.showfailedmsg('Something went wrong');       
                  console.log(error)        
      });
        },

    }
}
</script>
<style>
.toppadservice { padding: 10px 0;}

.minus { border:1px solid #F275BF !important; 
background: #F275BF !important;
color: #fff !important;
padding: 0.4rem 0.75rem 0.5rem;
font-size: 0.8rem;
border-radius: 5px 0 0 5px !important;
}
.plus { border:1px solid #2BD6C5 !important;
background:#2BD6C5 !important; 
padding: 0.4rem 0.75rem 0.5rem;
font-size: 0.8rem;
color: #fff !important;
border-radius: 0 5px 5px 0 !important;
}
.padvalleft {padding-left: 3% !important;}
.mheight { height: 10px; }
.quantity { width: 47%; padding: 5px; border: 1px solid #ced4da; position: relative; top: 1px; text-align: center;}


  @import '../../assets/dist/css/custom.css'  
</style>